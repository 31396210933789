.root {
  z-index: 9999 !important;
}

.gradientGreenText {
  background: linear-gradient(90deg, #57cbaf 0%, #2fa84f 100%);
  background-clip: text;
  color: transparent;
}

.gradientYellowText {
  background: linear-gradient(90deg, #f6d365 0%, #fda085 100%);
  background-clip: text;
  color: transparent;
}

.gradientRedText {
  background: linear-gradient(90deg, #f9567e 16.28%, #fa5252 84.82%);
  background-clip: text;
  color: transparent;
}

.gradientPurpleText {
  background: linear-gradient(90deg, #80688e 0%, #4f586a 100%);
  background-clip: text;
  color: transparent;
}

.gradientGreen {
  background: linear-gradient(90deg, #57cbaf 0%, #2fa84f 100%);
}

.gradientYellow {
  background: linear-gradient(90deg, #f6d365 0%, #fda085 100%);
}

.gradientRed {
  background: linear-gradient(90deg, #f9567e 16.28%, #fa5252 84.82%);
}
.gradientBlack {
  background: linear-gradient(90deg, #201f1e -0.34%, #495057 99.66%);
}
.gradientPurple {
  background: linear-gradient(90deg, #80688e 0%, #4f586a 100%);
}

.gradientGreenBorder {
  border-radius: 10px;
  border: solid 5px transparent;
  background-image: linear-gradient(#57cbaf 0%, #2fa84f 100%),
    linear-gradient(90deg, #57cbaf 0%, #2fa84f 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.gradientYellowBorder {
  border-radius: 10px;
  border: solid 5px transparent;
  background-image: linear-gradient(90deg, #f6d365 0%, #fda085 100%),
    linear-gradient(90deg, #f6d365 0%, #fda085 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.gradientRedBorder {
  border-radius: 10px;
  border: solid 5px transparent;
  background-image: linear-gradient(90deg, #f9567e 16.28%, #fa5252 84.82%),
    linear-gradient(90deg, #f9567e 16.28%, #fa5252 84.82%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.gradientPurpleBorder {
  border-radius: 10px;
  border: solid 5px transparent;
  background-image: linear-gradient(90deg, #80688e 0%, #4f586a 100%),
    linear-gradient(90deg, #80688e 0%, #4f586a 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.modal_box {
  background-color: #ffff;
  border-radius: 15px;
  padding: 20px;
  outline: none;
}

.modal_button {
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 35px;
  border-radius: 15px;
  border: none;
  color: #ffff;
  font-family: inherit;
}

.modal_header {
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 40px;

  .modal_title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1;
    font-size: 25px;
    font-weight: 600;
  }

  .modal_close {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
  }
}

.modal_container {
  display: flex;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  min-height: 100%;

  .modal_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-around;
    align-items: center;
    border-right: 1px solid #dbdbdb;
    width: 55%;
    height: 100%;
    padding: 50px 30px;
    text-align: center;
  }
  .modal_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5%;
    width: 45%;
    height: 100%;
  }
}

.modal_container_column {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal_state_text {
  font-size: 18px;
  font-weight: 600;
  margin-top: 40px;
}

.modal_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}

.modal_circle {
  border-radius: 99999px;
  border-width: 6px;
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .modal_box {
    padding: 20px;
  }

  .modal_button {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
    padding: 6px 40px;
  }

  .modal_header {
    display: flex;
    justify-content: center;
    align-content: center;

    .modal_title {
      font-size: 25px;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .modal_close {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 10px;
      height: 10px;
    }
  }

  .modal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-around;
    padding: 0 20px;
    width: 100%;

    .modal_left {
      border-bottom: 1px solid #dbdbdb;
      border-right: none;
      padding: 20px 30px;
      width: 100%;
    }
    .modal_right {
      text-align: center;
      padding: 5%;
      width: 100%;
      padding: 20px 0;
    }
  }

  .modal_container_column {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .modal_state_text {
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
  }

  .modal_text {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
  }

  .modal_circle {
    border-width: 5px;
    width: 100px;
    height: 100px;
  }

  .modal_circle_tick_img {
    margin-top: 30px;
    width: 70px;
    height: 70px;
  }

  .modal_circle_cross_img {
    width: 40px;
    height: 40px;
  }
}
