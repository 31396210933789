@import '../../styles/breakpoints.scss';

.container {
  display: flex;
  padding: 80px 44px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 1440px;
  width: 100%;

  opacity: 0;
  transform: translateY(20px);
  transition: 0.8s;
}

.visible {
  opacity: 1;
  transform: translateY(0px);
}

.title {
  color: var(--black-color);
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.56px;
}

.imagesContainer {
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4.5rem;

  @media (min-width: $breakpoint-md) {
    grid-template-columns: repeat(3, 1fr);
    gap: unset;
  }
}

.imageWrapper {
  place-self: center;
  max-width: 10rem;
}

.img {
  filter: grayscale(1);
  opacity: 0.50;
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;

  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}
