.border {
  border: 4px solid #fa5252;
  border-radius: 18px;
}

.modal_box {
  background-color: #ffff;
  padding: 12px 15px;
  outline: none;
}

.modal_header {
  display: flex;
  justify-content: center;
  align-content: center;

  .modal_title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1;
    font-size: 30px;
    font-weight: 700;
  }

  .modal_close {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
  }
}

.modal_container_column {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal_text {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 10px;
  text-align: center;
}

.modal_button {
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 10px;
  border: none;
  color: #ffff;
  background: linear-gradient(90deg, #f9567e 16.28%, #fa5252 84.82%);
  font-family: inherit;
}
