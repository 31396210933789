@import '../../styles/breakpoints';

.footer {
  --nav-color: var(--light);

  display: grid;
  width: 100%;
  position: relative;

  &.background {
    background: linear-gradient(90deg, #5059DB 0%, #5169E5);
  }

  @media print {
    display: none;
  }
}

.footerWave {
  width: 100%;
  position: relative;
  z-index: 2;
}

.footerShapes {
  animation: fadeIn 2s 0.5s ease-in-out forwards;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  object-fit: cover;
  object-position: -585px -718px;
  height: 100%;
  z-index: 1;

  @media all and (min-width: $breakpoint-lg) {
    object-position: 0 0;
  }
}

.footerContent {
  column-gap: 4rem;
  display: grid;
  grid-template-columns: 1fr;
  margin: 5rem auto;
  max-width: 1440px;
  padding: 0 1.5rem;
  position: relative;
  row-gap: 2.5rem;
  width: 100%;
  z-index: 3;

  @media all and (min-width: $breakpoint-md) {
    grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
    grid-template-rows: repeat(auto, max-content);
    grid-template-areas:
      'brand how-it-works'
      'learn how-it-works'
      'learn help'
      'learn topics';
    

    [data-name='brand'] {
      grid-area: brand;
    }

    [data-name='legal'] {
      grid-area: legal;
    }

    [data-name='help'] {
      grid-area: help;
    }

    [data-name='learn'] {
      grid-area: learn;
    }

    [data-name='how-it-works'] {
      grid-area: how-it-works;
    }

    [data-name='topics'] {
      grid-area: topics;
    }
  }

  @media all and (min-width: $breakpoint-lg) {
    grid-template-areas:
      'brand  how-it-works topics'
      'help   how-it-works topic'
      'learn  how-it-works help';
  }

  @media all and (min-width: $breakpoint-xxl) {
    grid-template-areas:
      'brand learn how-it-works topics'
      'help  learn how-it-works topics'
      'help  learn how-it-works topics'
      'help  learn how-it-works topics';
  }
}

.semrushText {
  color: #FFFFFF;
  margin-left: 57px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  background: #FFFFFF66;
  width: fit-content;
  padding: 4px 7px;
  border-radius: 4px;

  img {
    width: 112px;
    padding: 1px 5px 0;
  }
}

.footerBrandMessage {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 2rem;
}

.footerFineprint {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2.5rem;

  @media screen and (min-width: $breakpoint-md) {
    a,
    button {
      opacity: 0.85;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.logoHelpColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
  // flex: 1 0 0;
}

.logoAndInfoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  color: var(--white-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.link {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.notCapitalized {
  text-transform: none;
}

.button {
  border: none;
  background: transparent;
  text-align: left;
  padding: 0;
}

.title {
  color: #fcc419;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.56px;
  margin: 0.375rem 0 1.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  gap: 1rem;

  a,
  button {
    line-height: 1.5;
  }

  @media screen and (min-width: $breakpoint-md) {
    a,
    button {
      opacity: 0.85;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.smallFooter {
  min-height: 300px;
}

.footerBase {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-top: 3px solid var(--ocean-light);
  width: 100%;
  padding: 2.5rem 0;
}
