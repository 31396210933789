.root {
  z-index: 9999 !important;
}

.modal_box {
  background-image: url('../../public/assets/signup_background.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border: 2px solid #000;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  outline: none;
  padding: 15px;
  height: 100%;
  transition: 0.9s ease-in-out;

  .modal_header_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .modal_logo_container {
      color: var(--white-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 25px;
      text-transform: uppercase;
      &:first-child {
        font-weight: 300;
      }

      span {
        font-weight: 800;
      }
    }

    .modal_close {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  .modal_main_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    .modal_title {
      font-weight: 600;
      font-size: 30px;
      line-height: 40px;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: center;
    }

    .modal_text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      width: 70%;
      text-align: center;
    }

    .modal_video_text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      width: 75%;
      text-align: center;
      margin: 10px 0;
      margin-bottom: 15px;
    }

    .upgrade_btn {
      margin-top: 50px;
      font-size: 16px;
      font-weight: 600;
      padding: 15px 25px;
      border-radius: 15px;
      border: none;
      color: #ffff;
      font-family: inherit;
    }

    .modal_upgrade_text {
      color: #ffffff;
      max-width: 610px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin-top: 15px;
    }

    .list {
      max-width: 610px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-top: 20px;
      .add_perks {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 35px;
      }
      .ul {
        display: flex;
        align-items: center;
        align-content: space-around;
        align-self: flex-start;

        .dot {
          height: 7px;
          width: 7px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 15px;
          flex-shrink: 0;
          align-self: flex-start;
          margin-top: 15px;
        }

        .ul_text {
          color: white;
          line-height: 30px;
          font-size: 16px;
          font-weight: 400;
          line-height: 35px;
        }
      }
    }
  }

  .generate_animation {
    display: flex;
    justify-content: center;
    align-items: center;
    .modal_line {
      margin-left: 50px;
      width: 400px;
      height: 5px;
      background-image: linear-gradient(to right, #57cbaf 50%, transparent 50%);
      background-size: 60px;
      animation: animate1 600ms linear infinite reverse;
    }
    @keyframes animate1 {
      from {
        background-position: 0 0;
      }
      to {
        background-position: -60px 0;
      }
    }

    .modal_triangle {
      margin-right: 50px;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-left: 30px solid #57cbaf;
      border-bottom: 15px solid transparent;
    }

    @media screen and (max-width: 768px) {
      .modal_line {
        margin-left: 5vw;
        width: 60vw;
        height: 3px;
        background-image: linear-gradient(
          to right,
          #57cbaf 50%,
          transparent 50%
        );
        background-size: 30px;
        animation: animate1 1000ms linear infinite reverse;
      }

      .modal_triangle {
        margin-right: 5vw;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #57cbaf;
      }
    }
  }

  .gradientYellow {
    background: linear-gradient(90deg, #f6d365 0%, #fda085 100%);
  }
  .gradientPurple {
    background: linear-gradient(90deg, #80688e 0%, #4f586a 100%);
  }

  .gradientGreen {
    background: linear-gradient(90deg, #57cbaf 0%, #2fa84f 100%);
  }

  .gradientGray {
    background: linear-gradient(90deg, #dadada 0%, #a4a4a4 100%);
  }

  @media screen and (max-width: 768px) {
    .modal_header_container {
      padding: 0;
      flex-direction: row;
      position: relative;

      .modal_logo_container {
        font-size: 22px;
        gap: 5px;

        .modal_logo {
          width: 50px;
        }
      }

      .modal_close {
        display: flex;
        justify-content: flex-end;
        width: 40px;
        height: 40px;
      }
    }

    .modal_main_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;

      .modal_title {
        font-weight: 600;
        font-size: 25px;
        line-height: 40px;
        color: #ffffff;
        text-align: center;
        padding: 10px;
        margin-bottom: 20px;
      }

      .modal_text {
        color: #ffffff;
        font-size: 15px;
        font-weight: 400;
        width: auto;
        line-height: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
