@mixin filter_option {
  .option_title {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    color: var(--filter-label-color);
  }

  display: flex;
  flex-direction: column;
  gap: 5px;

  padding-bottom: 16px;

  .checkbox_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@mixin gradientText {
  @content;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin table_icon_container {
  $icon_size: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  max-width: $icon_size;
  max-height: $icon_size;
  min-height: $icon_size;
  min-width: $icon_size;

  margin-left: 15px;
  margin-right: 15px;
  transition: all 100ms ease-in;
}

@mixin table_icon_hover {
  &:hover {
    color: var(--purple-color);
    transition: all 100ms ease-in;
  }
}

@mixin button {
  transition: opacity 0.12s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}
