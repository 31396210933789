.testimonial {
  flex: 1;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  color: var(--black-color);
  text-align: center;
  max-width: 400px;
  border-radius: 24px;
  background: url('/assets/testimonials/testimonialCardBackground.png'),
    lightgray 50% / cover no-repeat;

  @media screen and (max-width: 839px) {
    max-width: 400px;
  }

  @media screen and (max-width: 790px) {
    margin-right: 20px;
    min-height: 500px;
  }

  @media screen and (max-width: 540px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 470px) {
    min-height: 560px;
  }

  @media screen and (max-width: 375px) {
    min-height: 610px;
  }
}

.userInfo {
  display: flex;
  text-align: left;
}

.imageContainer {
  border-radius: 50%;
  overflow: hidden;
  flex: none;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.romeo {
  background: url('/assets/testimonials/RomeoRazzi.png');
  background-size: cover;
}

.chris {
  background: url('/assets/testimonials/ChrisBrisson.png');
  background-size: cover;
}

.brian {
  background: url('/assets/testimonials/BrianDean.png');
  background-size: cover;
}

.osman {
  background: url('/assets/testimonials/OsmanHusain.png');
  background-size: cover;
}

.marissa {
  background: url('/assets/testimonials/MarissaStGermain.png');
  background-size: cover;
}

.joe {
  background: url('/assets/testimonials/JoeManna.png');
  background-size: cover;
}

.kat {
  background: url('/assets/testimonials/KatVonRohn.png');
  background-size: cover;
}

.igal {
  background: url('/assets/testimonials/IgalStolpner.png');
  background-size: cover;
}

.irina {
  background: url('/assets/testimonials/IrinaMaltseva.png');
  background-size: cover;
}

.peter {
  background: url('/assets/testimonials/PeterFoster.png');
  background-size: cover;
}

.gaetano {
  background: url('/assets/testimonials/GaetanoDiNardi.png');
  background-size: cover;
}

.ken {
  background: url('/assets/testimonials/KenSavage.png');
  background-size: cover;
}

.name {
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.392px;
  font-size: 18px;
  padding-bottom: 5px;
}

.company {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;

  & > span > a {
    cursor: pointer;
    color: #4e59e3;
    text-decoration-line: underline;
  }
}

.testimonialText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
