.modal_box {
  background-image: url('../../public/assets/signup_background.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border: 2px solid #000;
  border-radius: 15px;
  border: none;
  outline: none;
  height: 100%;
  transition: 0.9s ease-in-out;

  .modal_header_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .modal_logo_container {
      color: var(--white-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 25px;
      text-transform: uppercase;
      &:first-child {
        font-weight: 300;
      }

      span {
        font-weight: 800;
      }
    }

    .modal_close {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  .modal_main_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    .modal_title {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: center;
    }

    .modal_text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      width: 80%;
      text-align: center;
    }

    .upgrade_btn {
      margin-top: 40px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      padding: 15px 25px;
      border-radius: 12px;
      min-width: 250px;
      border: none;
      color: #ffff;
      background: linear-gradient(90deg, #f6d365 0%, #fda085 100%);
      font-family: inherit;
      text-transform: uppercase;
      &.gradientPurple {
        background: linear-gradient(90deg, #80688e 0%, #4f586a 100%);
      }
    }

    .list {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-top: 20px;
      margin-left: 60px;
      min-width: 450px;
      .ul {
        display: flex;
        align-items: center;
        align-content: space-around;
        align-self: flex-start;

        .dot {
          height: 7px;
          width: 7px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 15px;
          flex-shrink: 0;
          align-self: flex-start;
          margin-top: 10px;
          background: linear-gradient(90deg, #f6d365 0%, #fda085 100%);
        }

        .ul_text {
          color: white;
          line-height: 30px;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
        }
      }
    }
  }
}
