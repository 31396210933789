@import '../../styles/breakpoints.scss';
@import '../../styles/mixins.scss';

.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 61px;

  @media all and (max-width: $breakpoint-lg) {
    flex-direction: column;
  }
}

.content {
  max-width: 500px;
  @media all and (max-width: $breakpoint-lg) {
    max-width: 1000px;
  }

  .mainText {
    font-size: 32px;
    text-align: left;
    line-height: 2;
    margin: -8px 0 35px 0;

    @media all and (max-width: $breakpoint-lg) {
      font-size: 23px;
    }
  }

  .description {
    text-align: left;
    font-size: 20px;
  }

  .gradient {
    padding: 10px 0;
    animation: text 5s ease infinite;
    background: linear-gradient(270deg, #4D5AFF 7%, #FF4493 117.8%);
  }
}

.videoFrame {
  position: relative;
  span:first-child {
    overflow: visible !important;
  }

  &:hover {
    .videoTeaserImage {
      box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.15);
    }
  }
}

.videoTeaserImage {
  display: block;
  margin: 0 auto;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.12s ease-in-out;
}

.videoPlayOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: transprent;
  z-index: 0;

  &:hover,
  &:focus {
    .videoPlayButton {
      opacity: 1;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);

      transform: scale(1.125);

      @media all and (min-width: $breakpoint-lg) {
        transform: scale(1.625);
      }
    }
  }
}

.videoPlayButton {
  --size: 3.75rem;
  z-index: 10;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
  }

  width: var(--size);
  height: var(--size);
  background: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  border: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0.8;

  @media all and (min-width: $breakpoint-lg) {
    transform: scale(1.5);
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  img {
    position: relative;
    transform: translateX(0.25rem) translateY(1px);
  }
}

.ratingWrapper {
  display: flex;
  margin-top: 30px;
}

.images {
  cursor: pointer;
  margin-left: 15px;

  .imageWrapper {
    margin-left: -15px;
  }

  .roundedImage {
    border: 2px solid #e5e7eb;

    border-radius: 50%;
    position: relative;
  }
}

.trustedBlock {
  text-align: left;
  cursor: pointer;
  margin-left: 25px;
  font-size: 0.9em;
}

.buttons {
  display: flex;
  margin: 40px 0 0;
  justify-content: left;

  @media all and (max-width: $breakpoint-sm) {
    flex-direction: column;
    width: 100%;
  }

  button {
    width: 220px;
    margin-right: 20px;
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border: 3px solid;

    @media all and (max-width: $breakpoint-sm) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .whiteBtn {
    border-color: #9baafa;
    background: #fff;
    color: #495057;
  }

  .gradientBtn {
    background: linear-gradient(270deg, #4D5AFF 7%, #FF4493 117.8%);
    border-color: #414871;
    color: #FFF;
  }

}
