.container {
  display: flex;
  padding: 80px 44px 64px 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  max-width: 1440px;

  @media (max-width: 990px) {
    padding: 80px 24px 64px 24px;
  }

  @media (max-width: 790px) {
    padding: 56px 0px 64px 0px;
    max-width: 100vw;
  }
}

.text {
  color: var(--black-color);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.64px;

  @media (max-width: 790px) {
    line-height: 44px;
  }
}

.gradientText {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.64px;
  text-align: center;

  margin: 0 15%;

  background: linear-gradient(270deg, #4d5aff 7%, #ff4493 117.8%);
  @media (max-width: 790px) {
    line-height: 44px;
    width: 100%;
    margin: 0;
  }
}

.testimonialsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 72px;
  border-spacing: 72px;

  @media (max-width: 990px) {
    gap: 16px;
  }

  @media (max-width: 790px) {
    display: none;
  }
}

.testimonialsContainerMobile {
  display: none;

  @media (max-width: 790px) {
    display: block;
    min-width: 100%;
    overflow: hidden;
    touch-action: pan-y;
  }
}

.carousel {
  & > div > div > div > div > div > div {
    min-width: 310px;

    @media (max-width: 375px) {
      min-width: 0;
    }
  }
}

.textContainer {
  @media (max-width: 790px) {
    padding: 0 24px;
  }
}
