@import '../../styles/breakpoints.scss';

.container {
  padding: 1.5rem;
  width: 100%;

  @media all and (min-width: $breakpoint-lg) {
    padding: 2rem;
    min-width: 50rem;
    width: auto;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;

  .heading {
    font-family: inherit;
    max-width: fit-content;
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0;

    @media all and (max-width: 840px) {
      font-size: 1.5rem;
    }

    @media all and (max-width: 500px) {
      font-size: 1.2rem;
    }
  }
}

.close_btn {
  background-color: white;
  border: 0;
  border-radius: 5px;
  width: 60px;
  height: 60px;
  margin-left: auto;

  @media all and (max-width: 840px) {
    width: 50px;
    height: 50px;
  }
}

.iframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; // 16:9

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
