@import '../../styles/breakpoints';
@import '../../styles/mixins.scss';

@mixin heroOffset {
  position: relative;
  transform: translateY(-3rem);
}

.hero {
  align-items: center;
  background: linear-gradient(90deg, #5059DB 0%, #5169E5);
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  margin: 0 auto;
  padding: calc(var(--header-height) + 3rem) 1.5rem 8.5rem;
  text-align: center;
  width: 100%;
  min-height: 41rem;
  position: relative;
}

.heroInner {
  max-width: 1280px;
}

.heroContent {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  position: relative;
  z-index: 3;

  @media all and (min-width: $breakpoint-lg) {
    transform: translateY(-5rem);
  }

  @media all and (max-width: $breakpoint-md) {
    margin-top: 0;
  }
}

.heroTitle {
  font-size: 2rem;
  line-height: normal;
  letter-spacing: -0.64px;
  margin: 0;

  @media all and (min-width: $breakpoint-sm) {
    font-size: 2.5rem;
  }

  @media all and (min-width: $breakpoint-md) {
    font-size: 3rem;
  }
}

.heroText {
  font-size: 24px;
  line-height: 38px;
  letter-spacing: -0.252px;
  max-width: 78rem;
  margin: 0 auto;
}

.semrushText {
  text-transform: uppercase;
  display: flex;
  margin: auto;
  font-weight: 600;
  font-size: 22px;
  align-items: center;
}

.semrushLogo {
  width: 188px;
  padding: 3px 5px 0;
}


.yellowButton {
  color: #000;
  background: #ffce4f;
  border: none;
  font-weight: 700;
  padding: 17px 0;
  text-transform: uppercase;
  margin: auto;
  font-size: 16px;
  border-radius: 12px;
  width: 320px;
  @include button;
}

.heroWave {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.heroShapes {
  animation: fadeIn 2s 0.5s ease-in-out forwards;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  object-fit: cover;
  object-position: -585px -718px;
  height: 100%;

  @media all and (min-width: $breakpoint-lg) {
    object-position: 0 0;
  }
}

.ratesTestimonials {
  display: flex;
  margin-bottom: 50px;

  @media all and (min-width: $breakpoint-xl) {
    margin-bottom: 70px;
  }

  @media all and (max-width: $breakpoint-md) {
    flex-direction: column;
  }

  .ratesTestimonial {
    padding: 10px 50px;
    cursor: pointer;
  }

  .text {

  }
}
