@import '../../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black-color);
  width: 100%;
  align-self: center;

  @media screen and (max-width: 450px) {
    background-position: right -20px;
  }

  @media screen and (max-width: 550px) {
    background-position: right -5%;
    background-size: 300%;
  }

  @media screen and (max-width: 839px) {
    background-position: 0px 0px;
    background-size: 130%;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black-color);
  width: 100%;
  padding: 5rem 1.5rem;
  max-width: 1440px;
  align-self: center;

  h2,
  p {
    margin: 0;
  }
}

.image {
  overflow: hidden;
  width: 0;
  padding-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 930px) {
      width: 200%;
      object-position: -250px 0;
    }
  }
}

.visible {
  width: 100%;
  clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  animation: revealImage 1s linear forwards;
}

.title {
  text-align: center;
  color: #000;
  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.88px;
  margin-bottom: 20px;

  @media screen and (max-width: 1050px) {
    font-size: 38px;
  }

  @media screen and (max-width: 930px) {
    font-size: 36px;
  }

  @media screen and (max-width: 839px) {
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  @media screen and (max-width: 765px) {
    font-size: 28px;
  }

  @media screen and (max-width: 685px) {
    font-size: 24px;
  }

  @media screen and (max-width: 550px) {
    font-size: 32px;
    letter-spacing: -0.64px;

    text-align: left;
  }
}

.blue_text {
  color: #5249d9;
  font-family: Montserrat;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1.08px;
  text-transform: capitalize;

  @media screen and (max-width: 1050px) {
    font-size: 48px;
  }

  @media screen and (max-width: 930px) {
    font-size: 40px;
  }

  @media screen and (max-width: 839px) {
    font-size: 36px;
    letter-spacing: -0.72px;
  }

  @media screen and (max-width: 765px) {
    font-size: 32px;
  }

  @media screen and (max-width: 685px) {
    font-size: 28px;
  }

  @media screen and (max-width: 550px) {
    font-size: 40px;
    letter-spacing: -0.8px;
  }
}

.subtitle {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: 40px;

  @media screen and (max-width: 839px) {
    font-size: 18px;
    letter-spacing: -0.36px;
  }

  @media screen and (max-width: 550px) {
    text-align: left;
  }
}

.features_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 973px;
  gap: 19px;

  @media screen and (max-width: 980px) {
    width: auto;
  }

  @media screen and (max-width: 839px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 550px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin: 40px 0 20px 0;
  }
}

.feature_wrapper {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  @media screen and (max-width: 550px) {
    flex-shrink: 0;
  }
}

.feature_title {
  color: #828282;
  font-family: Montserrat;
  font-size: 14.719px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.206px;
  text-align: center;

  @media screen and (max-width: 550px) {
    font-size: 16px;
    letter-spacing: -0.224px;
  }
}

.semrushText {
  text-transform: uppercase;
  display: flex;
  padding-left: 50px;
  margin: auto;
  font-weight: 600;
  font-size: 20px;
  align-items: center;

  @media (max-width: 455px) {
    padding-left: 0;
    font-size: 16px;
  }
}

.semrushLogo {
  width: 188px;
}

.get_started_button {
  border-radius: 12px;
  border: none;
  background: #ffce4f;
  display: flex;
  width: 376px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;

  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  @include button;

  p {
    margin: 0;
  }

  @media (max-width: 765px) {
    width: 100%;
  }
}

.semrushWrapper {
  margin-top: 100px;
  margin-bottom: -55px;
  display: flex;

  .get_started_button {
    font-size: 20px;
  }

  @media (max-width: 765px) {
    flex-direction: column;
  }
}

@keyframes revealImage {
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
