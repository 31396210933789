@import '../../styles/mixins';

.headerContainer {
  display: inline-flex;
  padding: 0 72px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--header-height-desktop);
  flex-wrap: wrap;
  position: relative;
  z-index: 1;

  &.dark {
    --nav-color: var(--white-color);
  }

  &.light {
    --nav-color: var(--black-color);
  }
}

.semrushLogo {
  max-width: 180px
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 25px;
  text-transform: uppercase;
  text-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.2);

  &:first-child {
    font-weight: 300;
  }

  .logo_name {
    color: var(--nav-color);
    span {
      font-weight: 800;
    }
  }
}

.logo {
  filter: drop-shadow(0px 1.2px 3.6px rgba(0, 0, 0, 0.2));
}

.navBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
}

.navLink {
  color: var(--nav-color);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.28px;
  text-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.2);
  transition: opacity 0.12s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}

.dashboardBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.75rem;
  color: var(--black-color);
  background: var(--yellow-color);
  min-width: 13rem;
  height: 56px;
  padding: 1rem 2rem;
  font-size: 20px;
  font-weight: 600;
  font-family: inherit;
  line-height: 24px;
  text-transform: uppercase;

  box-shadow: 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  @include button;
}

@media screen and (max-width: 834px) {
  .headerContainer {
    padding: 0px 12px 0px 24px;
  }
}

@media screen and (max-width: 390px) {
  .headerContainer {
    display: flex;
    padding: 0px 12px;
    justify-content: space-between;
    align-items: center;

    .logoContainer {
      font-size: 18px;
    }
  }
}
