@import '../../styles/mixins.scss';

.hamburger {
  background: transparent;
  border: none;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
  background-color: var(--light);
  width: 100%;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  height: 0;
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;

  &.dropdown {
    padding: 3rem 1.5rem;
    height: 95vh;
  }

  .signout_btn {
    display: flex;
    align-items: center;
    line-height: 35px;
    height: 40px;
    padding: 0;
    background: none;
    border: none;

    cursor: pointer;
  }

  .nav_link {
    color: var(--black-color);
    border-bottom: 4px solid transparent;
    padding-bottom: 10px;
  }

  .nav_link:hover {
    border-bottom: 4px solid var(--black-color);
  }
}

.dashboardBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 12px;

  color: var(--black-color);
  background: var(--yellow-color);

  width: 366px;
  height: 56px;
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 600;
  font-family: inherit;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  @include button;
}
