.topicRankerFor {
  display: flex;
  padding: 80px 44px 124px 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  max-width: 1440px;

  opacity: 0;
  transform: translateY(20px);
  transition: 0.8s;

  .title {
    color: var(--black-color);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.56px;

    @media screen and (max-width: 834px) {
      letter-spacing: -0.48px;
    }

    @media screen and (max-width: 500px) {
      line-height: 32px;
    }
  }
}

.visible {
  opacity: 1;
  transform: translateY(0px);
}

.userTypesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  filter: grayscale(1);

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .profession {
    color: var(--black-color);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.392px;

    @media screen and (max-width: 834px) {
      font-size: 20px;
      letter-spacing: -0.28px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
      letter-spacing: -0.336px;
    }
  }
}
