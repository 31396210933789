@import '../../styles/breakpoints';
.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  display: grid;
  grid-template-columns: 3rem max-content;
  align-items: center;
  gap: 0.5rem;
}

.logoText {
  color: var(--nav-color);
  font-weight: 300;
  font-size: 1.25rem;
  text-transform: uppercase;
  text-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.2);

  @media all and (min-width: $breakpoint-md) {
    font-size: 1.5rem;
  }

  span {
    font-weight: 800;
  }
}

.semrushLogoText {
  color: var(--nav-color);
  font-weight: 600;
  font-size: 1.25rem;
  text-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.2);

  @media all and (min-width: $breakpoint-md) {
    font-size: 1.5rem;
  }
}
