.header {
  transition: all 0.2s ease-in-out;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: var(--header-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 100%;
    background: linear-gradient(270deg, #4a68ed 11.71%, #5249d9 100%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}

.headerActive {
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1);

  &:after {
    opacity: 1;
  }
}

.headerBanner {
  color: #FFF;
  background: #fa5252;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
  z-index: 10;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}

.headerBannerActive {
  display: none;
}
